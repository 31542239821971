import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EtnActive } from '@model/etn-active';
import { injectQuery } from '@ngneat/query';
import { QUERY_KEYS } from '../lib/constants';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class EtnService {
  #http = inject(HttpClient);
  #query = injectQuery();

  getEtnActiveList() {
    return this.#query({
      queryKey: [QUERY_KEYS.ETN_ACTIVE_LIST],
      queryFn: () =>
        this.#http.get<EtnActive[]>(
          environment.apiUrlPrefix + 'etn/active/list',
          { withCredentials: true }
        ),
    });
  }
}
