import { Component } from '@angular/core';
import { PageLayoutComponent } from '@components/layout/page-layout/page-layout.component';
import { TabViewModule } from 'primeng/tabview';
import { ReportsDataComponent } from '../../components/reports-data/reports-data.component';
import { ReportsExportComponent } from '../../components/reports-export/reports-export.component';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    PageLayoutComponent,
    TabViewModule,
    ReportsDataComponent,
    ReportsExportComponent,
  ],
  templateUrl: './reports.component.html',
})
export class ReportsComponent {}
