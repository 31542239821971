import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { injectQuery, injectMutation, injectQueryClient } from '@ngneat/query';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ReportExportService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #queryClient = injectQueryClient();
  #messageService = inject(MessageService);

  getReport() {
    return this.#mutation({
      mutationFn: (options: {
        dateFrom: string;
        dateTo: string;
        symbol: string;
        isCash: boolean;
        isPdf: boolean;
      }) => {
        return this.#http.get<Blob>(
          environment.apiUrlPrefix + 'nbrk/coin-statement',
          {
            params: {
              before: options.dateFrom,
              after: options.dateTo,
              symbol: options.symbol,
              isCash: options.isCash,
              isPdf: options.isPdf,
            },
            withCredentials: true,
            responseType: 'blob' as 'json',
          }
        );
      },
      onSuccess: (res, { dateFrom, dateTo, isCash, isPdf }) => {
        this.#messageService.add({
          severity: 'success',
          summary: '',
          detail: 'Выписка сформирована',
        });
        const format = isPdf ? 'pdf' : 'xlsx';
        const anchor = document.createElement('a');
        anchor.download = `Выписка ${
          isCash ? 'по счету' : 'по валюте'
        } ${dateFrom} ${dateTo}.${format}`;
        anchor.href = window.URL.createObjectURL(res);
        anchor.click();
      },
      onError: () => {
        this.#messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Выписка не сформирована',
        });
      },
    });
  }
}
