import { Component, computed, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CreateProductComponent } from '@components/create-product/create-product.component';
import { ProductEditableComponent } from '@components/product-editable/product-editable.component';
import { BranchBalance } from '@model/branch-balance';
import { TableColumn } from '@model/table-column';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { BranchBalanceService } from 'src/app/services/branch-balance.service';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { BranchService } from 'src/app/services/branch.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  Permissions,
  PermissionsService,
} from 'src/app/services/permissions.service';
import { OwnBranchService } from 'src/app/services/own-branch.service';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    TableModule,
    RouterLink,
    SkeletonModule,
    ProductEditableComponent,
    ButtonModule,
    CreateProductComponent,
    PageLayoutComponent,
    DropdownModule,
    ReactiveFormsModule,
  ],
  templateUrl: './products.component.html',
})
export class ProductsComponent {
  permissions = inject(PermissionsService).permissions;
  shouldDisplayBranchesFilter = computed(() =>
    this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)
  );
  branches = inject(BranchService).getBranchList().result;
  branchesDropdownOptions = computed(() => [
    { label: 'Все', value: null },
    ...(this.branches().data?.map((b) => ({ label: b.id, value: b.id })) || []),
  ]);
  ownBranchId = inject(OwnBranchService).getOwnBranch().result$;
  selectedBranchId = new FormControl<number | null>(null);
  productListService = inject(BranchBalanceService);
  productListDef = this.productListService.getBranchBalanceList();
  productList = this.productListDef.result;
  cols: TableColumn<BranchBalance>[] = [
    {
      field: 'branchId',
      header: 'ID филиала',
    },
    {
      field: 'coinName',
      header: 'Наименование монеты',
    },
    {
      field: 'quantity',
      header: 'Остаток в филиале',
    },
  ];

  ngOnInit() {
    this.ownBranchId.subscribe((value) => {
      if (!value) return;

      this.selectedBranchId.patchValue(
        this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)
          ? null
          : value.data!
      );
      this.productListDef.updateOptions(
        this.productListService.getBranchBalanceListOptions({
          branchId: this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)
            ? undefined
            : value.data!,
        })
      );
    });
  }

  handleBranchChange() {
    this.productListDef.updateOptions(
      this.productListService.getBranchBalanceListOptions({
        branchId: this.selectedBranchId.value as number,
      })
    );
  }
}
