<app-page-layout title="Заказы НБРК">
  <app-filters></app-filters>
  <div class="grow">
    @if (!!orders().isError) {
    <p class="text-red-400">
      {{ orders().error?.message || "Что-то пошло не так" }}
    </p>

    }
    <p-table
      [scrollable]="true"
      scrollHeight="65vh"
      [columns]="cols"
      [loading]="orders().isLoading"
      [value]="orders().data?.data || []"
      class="grow h-full w-full"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 20, 50, 100]"
      [rows]="paginationParams.pageSize || 10"
      [first]="paginationParams.first"
      [lazy]="true"
      (onLazyLoad)="onLazyLoad($event)"
      [totalRecords]="totalRecords()"
      styleClass="p-datatable-gridlines p-datatable-sm"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (item of columns; track item.id) {
          <th class="min-w-24">{{ item.header }}</th>
          }
          <th class="w-5"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [class]="rowData.status === 'PAID' ? 'bg-green-50' : ''">
          @for (col of columns; track col.field) { @if (col.field ===
          'dateOfReceipt') {
          <td>{{ rowData[col.field] | date : "dd.MM.yyyy" }}</td>

          } @else if (col.field === 'price') {
          <td>{{ rowData[col.field] | formatPrice }}</td>
          } @else if (col.field === 'status') {
          <td>{{ rowData[col.field] | formatOrderStatus }}</td>
          } @else {

          <td>{{ rowData[col.field] }}</td>
          } }
          <td>
            <a
              [routerLink]="rowData.id"
              [queryParamsHandling]="'preserve'"
              class="text-primary underline hover:opacity-80 transition-colors"
            >
              Детали
            </a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="9">
            <p class="text-center">Нет данных</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</app-page-layout>
