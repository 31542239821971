<form
  [formGroup]="filtersForm"
  class="flex items-center flex-wrap gap-4 w-full"
>
  <p-iconField iconPosition="left">
    <p-inputIcon styleClass="pi pi-search" />
    <input
      type="text"
      (ngModelChange)="handleFilterChange()"
      pInputText
      placeholder="ФИО"
      formControlName="fio"
    />
  </p-iconField>
  <p-iconField iconPosition="left">
    <p-inputIcon styleClass="pi pi-search" />
    <input
      type="text"
      (ngModelChange)="handleFilterChange()"
      pInputText
      placeholder="ИИН"
      formControlName="iin"
    />
  </p-iconField>
  <p-dropdown
    (ngModelChange)="handleFilterChange()"
    [options]="statuses"
    placeholder="Статус"
    formControlName="status"
  />
  @if (shouldDisplayBranchesFilter()) {

  <p-dropdown
    (ngModelChange)="handleFilterChange()"
    [options]="branchDropdownOptions()"
    placeholder="Филиал"
    formControlName="branch"
    optionLabel="name"
    optionValue="code"
  />
  }

  <p-button
    type="button"
    label="Сброс"
    [outlined]="true"
    (click)="resetFilters()"
  ></p-button>
</form>
