import { formatDate } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { EtnService } from 'src/app/services/etn.service';
import { ReportExportService } from 'src/app/services/report-export.service';

@Component({
  selector: 'app-reports-export',
  standalone: true,
  imports: [
    CalendarModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    InputSwitchModule,
  ],
  templateUrl: './reports-export.component.html',
})
export class ReportsExportComponent {
  selectDateForm = new FormGroup<{
    dateFrom: FormControl<Date | null | ''>;
    dateTo: FormControl<Date | null | ''>;
    coinSymbol: FormControl<string | null | ''>;
    isPdf: FormControl<boolean | null>;
  }>({
    dateFrom: new FormControl('', [Validators.required]),
    dateTo: new FormControl('', [Validators.required]),
    coinSymbol: new FormControl('', [Validators.required]),
    isPdf: new FormControl(
      localStorage.getItem('report-export-is-pdf') === 'true'
    ),
  });

  exportReport = inject(ReportExportService).getReport();
  exportReportResult = this.exportReport.result;

  etnActiveList = inject(EtnService).getEtnActiveList().result;
  coinsDropdownOptions = computed(() =>
    this.etnActiveList()
      .data?.filter((coin) => coin.coin)
      .map((coin) => ({
        label: coin.symbol,
        value: coin.symbol,
      }))
  );

  onSubmit() {
    if (!this.selectDateForm.valid) {
      return;
    }

    const formattedDateFrom = formatDate(
      this.selectDateForm.value.dateFrom! as Date,
      'yyyy-MM-dd',
      'en-US'
    );
    const formattedDateTo = formatDate(
      this.selectDateForm.value.dateTo! as Date,
      'yyyy-MM-dd',
      'en-US'
    );

    this.exportReport.mutate({
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      symbol: this.selectDateForm.value.coinSymbol!,
      isCash: false,
      isPdf: !!this.selectDateForm.value.isPdf,
    });
    this.exportReport.mutate({
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      symbol: this.selectDateForm.value.coinSymbol!,
      isCash: true,
      isPdf: !!this.selectDateForm.value.isPdf,
    });

    localStorage.setItem(
      'report-export-is-pdf',
      this.selectDateForm.value.isPdf ? 'true' : 'false'
    );
  }
}
