<form
  class="flex items-start gap-4 flex-col"
  [formGroup]="selectDateForm"
  (ngSubmit)="onSubmit()"
>
  <p-dropdown
    formControlName="coinSymbol"
    [options]="coinsDropdownOptions()"
    optionLabel="label"
    optionValue="value"
    placeholder="Выберите монету"
    [style]="{ width: '300px' }"
    [emptyMessage]="'Ничего не найдено'"
  ></p-dropdown>

  <p-calendar
    formControlName="dateFrom"
    [showIcon]="true"
    [style]="{ width: '300px' }"
    placeholder="Начало периода"
    [iconDisplay]="'input'"
    dateFormat="dd/mm/yy"
  ></p-calendar>

  <p-calendar
    formControlName="dateTo"
    [showIcon]="true"
    [style]="{ width: '300px' }"
    placeholder="Конец периода"
    [iconDisplay]="'input'"
    dateFormat="dd/mm/yy"
  ></p-calendar>

  <p-button
    type="submit"
    label="Получить выписку"
    [disabled]="!selectDateForm.valid || exportReportResult().isPending"
    [icon]="exportReportResult().isPending ? 'pi pi-spin pi-spinner' : ''"
  ></p-button>

  <div class="flex items-center gap-4 text-xs">
    <p-inputSwitch formControlName="isPdf" />
    <span> Выгрузить в формате PDF </span>
  </div>
</form>
