export enum OrderStatus {
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  RECEIVED = 'RECEIVED',
}

export const formatOrderStatusLabel = (
  status: OrderStatus | string | null | undefined
) => {
  if (!status) {
    return '';
  }

  if (status === OrderStatus.PAID) {
    return 'Оплачен';
  }

  if (status === OrderStatus.CANCELLED) {
    return 'Отменен';
  }

  if (status === OrderStatus.RECEIVED) {
    return 'Выдан';
  }

  return status;
};

export const orderStatusMenuItems: {
  label: string;
  value: OrderStatus | null;
}[] = [
  {
    label: 'Все статусы',
    value: null,
  },
  ...Object.values(OrderStatus).map((status) => ({
    label: formatOrderStatusLabel(status),
    value: status,
  })),
];
