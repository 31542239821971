import { Component, computed, inject, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { BranchService } from 'src/app/services/branch.service';

@Component({
  selector: 'app-report-filters',
  standalone: true,
  imports: [DropdownModule, CalendarModule, FormsModule, ButtonModule],
  templateUrl: './filters.component.html',
})
export class FiltersComponent {
  filters = input.required<{
    date: Date[];
    coinType: string | null;
    operationType: string | null;
    branchId: number | null;
  }>();
  onChange = output();
  onReset = output();

  branches = inject(BranchService).getBranchList().result;

  branchDropdownOptions = computed<{ name: string; code: string | null }[]>(
    () => {
      return [
        {
          name: 'Все филиалы',
          code: null,
        },
        ...(this.branches().data?.map((branch) => ({
          name: branch.id.toString(),
          code: branch.id.toString(),
        })) || []),
      ];
    }
  );

  operationTypesDropdownOptions = [
    {
      name: 'Все типы операции',
      code: null,
    },
    {
      name: 'Покупка',
      code: 'BUY',
    },
    {
      name: 'Продажа',
      code: 'SELL',
    },
    {
      name: 'Получение',
      code: 'RECEIVE',
    },
  ];

  coinTypesDropdownOptions = computed<{ name: string; code: string | null }[]>(
    () => {
      return [
        {
          name: 'Все монеты',
          code: null,
        },
        {
          name: 'ЦМ',
          code: 'DC',
        },
        {
          name: 'ИМ',
          code: 'IC',
        },
      ];
    }
  );

  onFiltersChange() {
    this.onChange.emit();
  }

  onResetFilters() {
    this.onReset.emit();
  }
}
