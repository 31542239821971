import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { BranchService } from 'src/app/services/branch.service';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SkeletonModule } from 'primeng/skeleton';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-branch-editable',
  standalone: true,
  imports: [
    InputTextModule,
    ButtonModule,
    RouterLink,
    ReactiveFormsModule,
    SkeletonModule,
  ],
  templateUrl: './branch-editable.component.html',
})
export class BranchEditableComponent {
  id = inject(ActivatedRoute).snapshot.paramMap.get('id');
  branchDetails = inject(BranchService).getBranch(Number(this.id)).result;
  updateBranch = inject(BranchService).updateBranch();
  updateBranchResult = this.updateBranch.result;

  deleteBranch = inject(BranchService).deleteBranch();
  deleteBranchResult = this.deleteBranch.result;
  confirmationService = inject(ConfirmationService);

  branchDetailsForm = computed(
    () =>
      new FormGroup({
        id: new FormControl(this.branchDetails().data?.id || ''),
        name: new FormControl(this.branchDetails().data?.name || '', [
          Validators.required,
        ]),
        address: new FormControl(this.branchDetails().data?.address || '', [
          Validators.required,
        ]),
        city: new FormControl(this.branchDetails().data?.city || '', [
          Validators.required,
        ]),
      })
  );

  handleSubmit() {
    if (!this.branchDetailsForm().valid) return;
    this.updateBranch.mutate({
      id: Number(this.branchDetailsForm().value.id),
      name: this.branchDetailsForm().value.name as string,
      address: this.branchDetailsForm().value.address as string,
      city: this.branchDetailsForm().value.city as string,
    });
  }

  handleDelete() {
    this.confirmationService.confirm({
      message: 'Удалить этот филиал?',
      header: 'Подтвердите действие',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text ',
      rejectButtonStyleClass: 'p-button-text ',
      accept: () => {
        this.deleteBranch.mutate(Number(this.branchDetailsForm().value.id));
      },
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
    });
  }
}
