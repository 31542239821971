import { Component, computed, inject } from '@angular/core';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { TableColumn } from '@model/table-column';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { OrdersService } from 'src/app/services/orders.service';
import { SkeletonModule } from 'primeng/skeleton';
import { FiltersComponent } from './filters/filters.component';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import { DataEntity } from '@model/orders';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DatePipe } from '@angular/common';
import { OrderFilters } from '@model/order-filters';
import { PermissionsService } from 'src/app/services/permissions.service';
import { FormatPricePipe } from 'src/app/pipes/format-price.pipe';
import { FormatOrderStatusPipe } from 'src/app/pipes/format-order-status.pipe';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    HeadingOneComponent,
    TableModule,
    SkeletonModule,
    FiltersComponent,
    PageLayoutComponent,
    RouterLink,
    DatePipe,
    FormatPricePipe,
    FormatOrderStatusPipe,
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css',
})
export class OrdersComponent {
  ordersService = inject(OrdersService);
  ordersDef = inject(OrdersService).getOrders();
  orders = this.ordersDef.result;
  route = inject(ActivatedRoute);
  router = inject(Router);
  permissions = inject(PermissionsService).permissions;

  filters = {
    status: null,
    fio: null,
    iin: null,
    branch: null,
  } as OrderFilters;

  paginationParams = {
    pageSize: 10,
    pageNumber: 0,
    first: 0,
  };

  totalRecords = computed(() => this.orders().data?.total || 0);

  constructor() {
    this.route.queryParams.subscribe((params) => {
      this.filters = {
        fio: params['fio'] || null,
        iin: params['iin'] || null,
        status: params['status'] || null,
        branch: params['branch'] || null,
      };

      this.paginationParams = {
        pageNumber: params['pageNumber'] || 0,
        pageSize: params['pageSize'] || 10,
        first: params['first'] || 0,
      };

      this.fetchOrders();
    });
  }

  cols: TableColumn<DataEntity>[] = [
    { field: 'id', header: 'N#' },
    { field: 'dateOfReceipt', header: 'Дата' },
    { field: 'fio', header: 'ФИО' },
    { field: 'iin', header: 'ИИН' },
    { field: 'status', header: 'Статус' },
    { field: 'quantity', header: 'Кол-во' },
    { field: 'departmentId', header: 'Филиал' },
  ];

  onLazyLoad(event: TableLazyLoadEvent) {
    this.paginationParams.first = event.first!;
    this.paginationParams.pageSize = event.rows!;
    this.paginationParams.pageNumber = event.first! / event.rows!;
    this.router.navigate([], {
      queryParams: {
        ...this.filters,
        ...this.paginationParams,
      },
    });
  }

  fetchOrders() {
    this.ordersDef.updateOptions(
      this.ordersService.getOrdersOptions({
        params: {
          filter: {
            ...this.filters,
            ...this.paginationParams,
          },
        },
      })
    );
  }
}
