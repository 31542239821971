import { computed, inject, Injectable } from '@angular/core';
import { AuthorizationService } from '../lib/core/authorization.service';

export enum Permissions {
  CAN_VIEW_USERS = 'CAN_VIEW_USERS',
  CAN_VIEW_BRANCHES = 'CAN_VIEW_BRANCHES',
  CAN_VIEW_PRODUCTS = 'CAN_VIEW_PRODUCTS',
  CAN_VIEW_OWN_ORDERS = 'CAN_VIEW_OWN_ORDERS',
  CAN_VIEW_ORDERS = 'CAN_VIEW_ORDERS',
  CAN_MODIFY_ORDERS = 'CAN_MODIFY_ORDERS',
  CAN_VIEW_COINS = 'CAN_VIEW_COINS',
  CAN_CREATE_COINS = 'CAN_CREATE_COINS',
  CAN_EDIT_COINS = 'CAN_EDIT_COINS',
  CAN_VIEW_REPORTS = 'CAN_VIEW_REPORTS',
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  #authInfo = inject(AuthorizationService).authInfo;

  constructor() {}

  permissions = computed(() => {
    const role = this.#authInfo()?.role;

    switch (role) {
      case 'NBRK_MANAGER':
        return [
          Permissions.CAN_VIEW_OWN_ORDERS,
          Permissions.CAN_MODIFY_ORDERS,
          Permissions.CAN_VIEW_COINS,
          Permissions.CAN_VIEW_PRODUCTS,
        ];

      case 'NBRK_AUDITOR':
        return [Permissions.CAN_VIEW_ORDERS, Permissions.CAN_VIEW_REPORTS];

      case 'NBRK_ADMIN':
        return [
          Permissions.CAN_VIEW_USERS,
          Permissions.CAN_VIEW_BRANCHES,
          Permissions.CAN_VIEW_PRODUCTS,
          Permissions.CAN_VIEW_ORDERS,
          Permissions.CAN_MODIFY_ORDERS,
          Permissions.CAN_VIEW_COINS,
          Permissions.CAN_CREATE_COINS,
          Permissions.CAN_EDIT_COINS,
          Permissions.CAN_VIEW_REPORTS,
        ];

      default:
        return [];
    }
  });
}
