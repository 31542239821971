import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { environment } from '@environment/environment';
import { CookieService } from 'ngx-cookie-service';
import { LOCALSTORAGE_KEYS } from '../constants';
import { NbrkRoles } from '@model/nbrk-roles';

export const authGuard: CanActivateFn = async (route, state) => {
  const authorizationService = inject(AuthorizationService);
  const cookieService = inject(CookieService);
  const router = inject(Router);

  try {
    const authInfo = await authorizationService
      .getUserInfoMutation()
      .mutateAsync({});

    if (!authInfo || !authInfo.accountName) {
      cookieService.delete(LOCALSTORAGE_KEYS.GG_TOKEN);
      window.location.href = environment.backofficeUrl;
      return false;
    }

    const role = authInfo.role;
    if (route.data['excludedRoles']?.includes(role)) {
      if (role === NbrkRoles.NBRK_MANAGER) {
        router.navigate(['/products']);
      }
      if (role === NbrkRoles.NBRK_AUDITOR) {
        router.navigate(['/orders']);
      }

      return false;
    }

    return true;
  } catch (error) {
    console.log(error);
    cookieService.delete(LOCALSTORAGE_KEYS.GG_TOKEN);
    window.location.href = environment.backofficeUrl;
    return false;
  }
};
