import { Component, computed, inject } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { FiltersComponent } from '../reports-data/filters/filters.component';
import { ExportReportButtonComponent } from '@components/export-report-button/export-report-button.component';
import { DatePipe, formatDate } from '@angular/common';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { TableColumn } from '@model/table-column';
import { DataEntity } from '@model/coin-report-list';
import { FormatPricePipe } from 'src/app/pipes/format-price.pipe';

@Component({
  selector: 'app-reports-data',
  standalone: true,
  imports: [
    FiltersComponent,
    ExportReportButtonComponent,
    TableModule,
    SkeletonModule,
    DatePipe,
    FormatPricePipe,
  ],
  templateUrl: './reports-data.component.html',
})
export class ReportsDataComponent {
  filters = {
    date: [],
    coinType: null,
    operationType: null,
    branchId: null,
  } as {
    date: Date[];
    coinType: string | null;
    operationType: string | null;
    branchId: number | null;
  };
  paginationParams = {
    pageSize: 10,
    pageNumber: 0,
    first: 0,
  };

  reportsService = inject(ReportsService);
  getReportsDef = this.reportsService.getReports({
    coinType: this.filters.coinType,
    operationType: this.filters.operationType,
    branchId: this.filters.branchId,
    dateFrom: this.filters.date[0]
      ? formatDate(this.filters.date[0], 'yyyy-MM-dd', 'en-US')
      : null,
    dateTo: this.filters.date[1]
      ? formatDate(this.filters.date[1], 'yyyy-MM-dd', 'en-US')
      : null,
    pageSize: this.paginationParams.pageSize,
    pageNumber: this.paginationParams.pageNumber,
  });
  reports = this.getReportsDef.result;
  totalElements = computed(() => this.reports().data?.total || 0);

  cols: TableColumn<DataEntity>[] = [
    {
      field: 'id',
      header: 'N#',
    },
    {
      field: 'date',
      header: 'Дата',
    },
    {
      field: 'coinType',
      header: 'Тип монеты',
    },
    {
      field: 'coinOperationType',
      header: 'Тип операции',
    },
    {
      field: 'quantity',
      header: 'Количество',
    },
    {
      field: 'weight',
      header: 'Вес (oz)',
    },
    {
      field: 'price',
      header: 'Цена',
    },
    {
      field: 'sum',
      header: 'Сумма',
    },
    {
      field: 'departmentId',
      header: 'Филиал',
    },
  ];

  handleFiltersChange() {
    this.getReportsDef.updateOptions(
      this.reportsService.getReportsOptions({
        coinType: this.filters.coinType,
        operationType: this.filters.operationType,
        branchId: this.filters.branchId,
        dateFrom: this.filters.date[0]
          ? formatDate(this.filters.date[0], 'yyyy-MM-dd', 'en-US')
          : null,
        dateTo: this.filters.date[1]
          ? formatDate(this.filters.date[1], 'yyyy-MM-dd', 'en-US')
          : null,
        pageNumber: this.paginationParams.pageNumber,
        pageSize: this.paginationParams.pageSize,
      })
    );
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    this.paginationParams.first = event.first!;
    this.paginationParams.pageSize = event.rows!;
    this.paginationParams.pageNumber = Math.floor(event.first! / event.rows!);
    this.handleFiltersChange();
  }

  handleFilterReset() {
    this.filters = {
      date: [],
      coinType: null,
      operationType: null,
      branchId: null,
    };

    this.handleFiltersChange();
  }

  formatCoinType(value: 'DC' | 'IC') {
    if (value === 'DC') {
      return 'ЦМ';
    }

    if (value === 'IC') {
      return 'ИМ';
    }

    return value;
  }

  formatCoinOperationType(value: 'BUY' | 'SELL' | 'RECEIVE') {
    if (value === 'BUY') {
      return 'Покупка';
    }

    if (value === 'SELL') {
      return 'Продажа';
    }

    if (value === 'RECEIVE') {
      return 'Получение';
    }

    return value;
  }
}
