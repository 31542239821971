<div class="flex items-center gap-4 flex-wrap">
  <p-calendar
    placeholder="Дата"
    [(ngModel)]="filters().date"
    (ngModelChange)="onFiltersChange()"
    dateFormat="dd/mm/yy"
    selectionMode="range"
  ></p-calendar>

  <p-dropdown
    [options]="branchDropdownOptions()"
    placeholder="Филиал"
    optionLabel="name"
    optionValue="code"
    [(ngModel)]="filters().branchId"
    (ngModelChange)="onFiltersChange()"
  ></p-dropdown>

  <p-dropdown
    [options]="coinTypesDropdownOptions()"
    placeholder="Тип монеты"
    optionLabel="name"
    optionValue="code"
    [(ngModel)]="filters().coinType"
    (ngModelChange)="onFiltersChange()"
  ></p-dropdown>

  <p-dropdown
    [options]="operationTypesDropdownOptions"
    placeholder="Тип операции"
    optionLabel="name"
    optionValue="code"
    [(ngModel)]="filters().operationType"
    (ngModelChange)="onFiltersChange()"
  ></p-dropdown>

  <p-button
    type="button"
    label="Сброс"
    [outlined]="true"
    (click)="onResetFilters()"
  ></p-button>
</div>
