import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { injectMutation, injectQuery, queryOptions } from '@ngneat/query';
import { MessageService } from 'primeng/api';
import { QUERY_KEYS } from '../lib/constants';
import { environment } from '@environment/environment';
import { CoinReportList } from '@model/coin-report-list';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #messageService = inject(MessageService);
  #mutation = injectMutation();

  getReports(filters: {
    dateFrom: string | null;
    dateTo: string | null;
    coinType: string | null;
    operationType: string | null;
    branchId: number | null;
    pageSize: number | null;
    pageNumber: number | null;
  }) {
    return this.#query(this.getReportsOptions(filters));
  }

  getReportsOptions(filters: {
    dateFrom: string | null;
    dateTo: string | null;
    coinType: string | null;
    operationType: string | null;
    branchId: number | null;
    pageSize: number | null;
    pageNumber: number | null;
  }) {
    return queryOptions({
      queryKey: [QUERY_KEYS.REPORTS, filters],
      queryFn: () =>
        this.#http.get<CoinReportList>(
          environment.apiUrlPrefix +
            'nbrk/coin-report-list?filter=' +
            encodeURIComponent(
              JSON.stringify({
                departmentId: filters.branchId,
                coinType: filters.coinType,
                coinOperationType: filters.operationType,
                before: filters.dateFrom,
                after: filters.dateTo,
                pageNumber: filters.pageNumber,
                pageSize: filters.pageSize,
              })
            ),
          {
            withCredentials: true,
          }
        ),
    });
  }

  exportReport() {
    return this.#mutation({
      mutationFn: (filters: {
        before: string | null;
        after: string | null;
        coinType: string | null;
        coinOperationType: string | null;
        departmentId: number | null;
      }) => {
        return this.#http.get<Blob>(
          environment.apiUrlPrefix +
            'nbrk/coin-report?filter=' +
            encodeURIComponent(JSON.stringify(filters)),
          {
            withCredentials: true,
            responseType: 'blob' as 'json',
          }
        );
      },
      onSuccess: (res, { before, after }) => {
        this.#messageService.add({
          severity: 'success',
          summary: '',
          detail: 'Отчет выгружен',
        });
        const anchor = document.createElement('a');
        if (before && after) {
          anchor.download = `Отчет ${before} ${after}.xlsx`;
        } else {
          anchor.download = `Отчет ${formatDate(
            new Date(),
            'yyyy-MM-dd',
            'en-US'
          )}.xlsx`;
        }
        anchor.href = window.URL.createObjectURL(res);
        anchor.click();
      },
      onError: () => {
        this.#messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Не удалось выгрузить отчет',
        });
      },
    });
  }
}
