import { formatDate } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-export-report-button',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './export-report-button.component.html',
})
export class ExportReportButtonComponent {
  filters = input.required<{
    date: Date[];
    coinType: string | null;
    operationType: string | null;
    branchId: number | null;
  }>();
  exportReport = inject(ReportsService).exportReport();
  exportReportResult = this.exportReport.result;

  handleExportReport() {
    this.exportReport.mutate({
      departmentId: this.filters().branchId,
      coinType: this.filters().coinType,
      coinOperationType: this.filters().operationType,
      before: this.filters().date[0]
        ? formatDate(this.filters().date[0], 'yyyy-MM-dd', 'en-US')
        : null,
      after: this.filters().date[1]
        ? formatDate(this.filters().date[1], 'yyyy-MM-dd', 'en-US')
        : null,
    });
  }
}
