import { Pipe, PipeTransform } from '@angular/core';
import { formatOrderStatusLabel } from '@model/order-status';

@Pipe({
  name: 'formatOrderStatus',
  standalone: true,
})
export class FormatOrderStatusPipe implements PipeTransform {
  transform(value?: string | null | undefined) {
    if (!value) {
      return null;
    }

    return formatOrderStatusLabel(value);
  }
}
