import { Pipe, PipeTransform } from '@angular/core';
import { formatPrice } from '@utils/formatPrice';

@Pipe({
  name: 'formatPrice',
  standalone: true,
})
export class FormatPricePipe implements PipeTransform {
  transform(value?: number | null): string | null {
    if (!value) return null;
    return formatPrice(value);
  }
}
