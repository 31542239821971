<section class="flex flex-col gap-8">
  <div class="flex items-start justify-between gap-4 flex-wrap">
    <app-report-filters
      [filters]="filters"
      (onChange)="handleFiltersChange()"
      (onReset)="handleFilterReset()"
    ></app-report-filters>
    <app-export-report-button [filters]="filters"></app-export-report-button>
  </div>

  @if(reports().isError) {
  <p class="text-red-500">
    {{ reports().error?.message || "Что-то пошло не так" }}
  </p>
  }
  <p-table
    styleClass="p-datatable-gridlines p-datatable-sm"
    [columns]="cols"
    [value]="reports().data?.data || []"
    [scrollable]="true"
    [scrollHeight]="'65vh'"
    [loading]="reports().isLoading"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 20, 50, 100]"
    [rows]="paginationParams.pageSize || 10"
    [first]="paginationParams.first"
    [lazy]="true"
    [totalRecords]="totalElements()"
    (onLazyLoad)="handleLazyLoad($event)"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for(col of columns; track col.id) {
        <th class="min-w-24">{{ col.header }}</th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @for (col of columns; track col.field) { @if (col.field ==='date') {
        <td>{{ rowData[col.field] | date : "dd.MM.yyyy" }}</td>
        } @else if (col.field === 'coinType') {
        <td>{{ formatCoinType(rowData[col.field]) }}</td>
        } @else if (col.field === 'coinOperationType') {
        <td>{{ formatCoinOperationType(rowData[col.field]) }}</td>
        } @else if (col.field === 'price' || col.field === 'sum') {
        <td>{{ rowData[col.field] | formatPrice }}</td>
        } @else {
        <td>{{ rowData[col.field] }}</td>
        } }
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="9" class="text-center">Ничего не найдено</td>
      </tr>
    </ng-template>
  </p-table>
</section>
