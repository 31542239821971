<app-page-layout title="Отчеты">
  <p-tabView>
    <p-tabPanel header="Отчеты">
      <app-reports-data></app-reports-data>
    </p-tabPanel>
    <p-tabPanel header="Выписки">
      <app-reports-export></app-reports-export>
    </p-tabPanel>
  </p-tabView>
</app-page-layout>
