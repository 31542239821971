<div class="h-full flex flex-col">
  <div class="p-4 flex items-center justify-center mb-6">
    <a routerLink="" class="w-14 pr-4 block">
      <nbrk-logo></nbrk-logo>
    </a>
    <a routerLink="" class="w-28 block">
      <aix-logo></aix-logo>
    </a>
  </div>

  <p-scroller [items]="linkItems()" [itemSize]="40" scrollHeight="100%">
    <ng-template pTemplate="item" let-item let-options="options">
      <a
        [class]="
          'py-3 px-4 block font-medium hover:bg-secondary hover:text-primary transition-colors'
        "
        [routerLink]="item.routerLink"
        [queryParamsHandling]="item.routerLink === 'orders' ? 'preserve' : ''"
        [routerLinkActive]="
          'font-bold text-white bg-primary hover:!bg-primary hover:text-white'
        "
        >{{ item.label }}</a
      >
    </ng-template>
  </p-scroller>
</div>
